import React, { Component } from 'react';
import './App.css';
import { Button, Container, Row, Col } from 'reactstrap';
import neptun from './images/neptunsolutions.png';
import kpvls1 from './images/kpvls1.jpg';
import kpvls2 from './images/kpvls2.jpg';
import logoSkola from './images/logoRuSkola.png';

import {  FaEnvelope } from 'react-icons/fa';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Container>
          <Row>
            <Col md="12">
              <img src={kpvls1} className="col-md-12" alt="logo" />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <h1>Klub Petra Veľkého a Ľudovíta Štúra</h1>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <img src={kpvls2} className="col-md-12" alt="logo" />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <div className="lead">
                <b>webová stránka klubu je v príprave</b>
                <br />
                <br />
                <FaEnvelope /> Email: <a href="mailto:info@kpvls.com">info@kpvls.com</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <div className="lead">
                Informácie pre darovanie finančných príspevkov<br />
              </div>
                IČO: 52101614<br />
                OBČIANSKE ZDRUŽENIE: KLUB PETRA VELKÉHO A ĽUDOVÍTA ŠTÚRA, O.Z.<br />
                Adresa: Straková  5, Bratislava 81101<br />
                Bankové údaje: Prima Banka<br />
                SWIFT: KOMASK2X<br />
                {/* Číslo účtu: 6283404001/5600<br /> */}
                IBAN: SK92 5600 0000 0062 8340 4001
              <div className="lead">
                Ďakujeme pekne / благодарю покорно<br />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <br />
              <div className="lead">
              Súkromná základná škola, Частная основная школа, Bratislava
              <br />
              rusky, slovensky a anglicky
              </div>
            </Col>
            <Col md="2">
              <br />
              <br />
              <Button color="danger" href='https://www.ruskaskola.sk/' target='blank'>Viac</Button>
              <br />
            </Col>
            <Col sm="6" md="2">
              <img src={logoSkola} alt="logo" width='80%' height='100%' />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <img src={neptun} alt="logo" width='100%' />
            </Col>
            <Col md="4">
            </Col>
            <Col md="2">
              <Button color="danger" href='https://www.neptunsolutions.com/' target='blank'>Viac</Button>
            </Col>
          </Row>
          <footer>
            <Row>
              <Col md="12">
              <br />
                <p>©{(new Date().getFullYear())} Klub Petra Veľkého a Ľudovíta Štúra</p>
              </Col>
            </Row>
          </footer>
        </Container>
      </div>
    );
  }
}

export default App;
